import React from "react";
import {useQuery} from "@tanstack/react-query";
import {fetchMarketOpenInterest} from "@/api/perpsDataFetcher";
import {ErrorPanel} from "@/components/ErrorPanel";
import {Tooltip as ReactTooltip} from "react-tooltip";
import useId from "@/hooks/useId";
import {SplitLabelValue} from "@/components/SplitLabelValue";
import {Market, OpenInterest} from "@/components/Perps/types";
import {parseUnits} from "viem";
import {isUsdToken} from "@/util/chainConstants";
import {MarketValueView} from "@/components/Perps/market/MarketValueView";


export type Props = {
    market: Market;
    openInterest?: OpenInterest[] | undefined;
    displayTotalPrincipal?: boolean;
};

const calculatePercentages = (market: Market, data: OpenInterest[] | undefined) => {
    if (!data || data.length === 0) {
        return { longPercentage: 50, shortPercentage: 50, longOpenInterest: 0, shortOpenInterest: 0 };
    }

    const longPosition = data.find(entry => entry.side === "LONG");
    const shortPosition = data.find(entry => entry.side === "SHORT");

    const totalPositions = data.reduce((total, entry) => total + entry.amount, 0);

    let longPercentage = longPosition ? (longPosition.amount / totalPositions) * 100 || 0 : 0;
    let shortPercentage = shortPosition ? (shortPosition.amount / totalPositions) * 100 || 0 : 0;

    if (longPosition && !shortPosition) {
        longPercentage = 100;
    } else if (shortPosition && !longPosition) {
        shortPercentage = 100;
    }

    const isUSDB = isUsdToken(market.baseTokenAddress);

    let longPrincipal = longPosition?.principal || 0;
    let shortPrincipal = shortPosition?.principal || 0;
    let longOpenInterest = longPosition?.amount || 0;
    let shortOpenInterest = shortPosition?.amount || 0;

    if (isUSDB) {
        const tmpLongPrincipal = longPrincipal;
        const tmpLongPercentage = longPercentage;
        const tmpLongOpenInterest = longOpenInterest;
        longPrincipal = shortPrincipal;
        longPercentage = shortPercentage;
        longOpenInterest = shortOpenInterest;
        shortPrincipal = tmpLongPrincipal;
        shortPercentage = tmpLongPercentage;
        shortOpenInterest = tmpLongOpenInterest;
    }

    return {
        longPercentage,
        shortPercentage,
        longOpenInterest,
        shortOpenInterest,
        longPrincipal,
        shortPrincipal,
        isUSDB,
    };
};

const renderSkeletonLoading = () => (
    <div className="rounded-md w-full max-w-[400px] h-[6px] bg-slate-600 animate-pulse"/>
);

export const OpenInterestView = ({market, openInterest, displayTotalPrincipal}: Props) => {
    const id = useId();
    const token = market.pair.baseToken;

    const openInterestStatsQuery = useQuery({
        queryKey: ["open_interest", market.id],
        queryFn: async () =>  openInterest || await fetchMarketOpenInterest(market.id),
        gcTime: 20 * 1000,
        staleTime: 20 * 1000,
        refetchInterval: 20 * 1000,
      }
    );

    if (openInterestStatsQuery.isLoading) {
        return renderSkeletonLoading();
    }

    if (openInterestStatsQuery.isError) {
        return <ErrorPanel message="Error loading open interest stats"/>;
    }

    const {longPercentage, shortPercentage, longOpenInterest, shortOpenInterest, longPrincipal, shortPrincipal, isUSDB} =
      calculatePercentages(market, openInterestStatsQuery.data);

    const maxOpenInterest = isUSDB ? market.maxShortPrincipal : market.maxLongPrincipal;
    const maxShortPrincipal = isUSDB ? market.maxLongPrincipal : market.maxShortPrincipal;

    return (
        <div className="flex items-center space-x-1 cursor-help" id={`oiv_${id}`}>
            <div
                className="h-[6px] bg-call rounded-l-md hover:bg-call-50"
                style={{width: `${longPercentage}%`}}
                data-tip={`Long: ${longPercentage.toFixed(2)}%`}
            />
            <div
                className="h-[6px] bg-put rounded-r-md hover:bg-call-50"
                style={{width: `${shortPercentage}%`}}
                data-tip={`Short: ${shortPercentage.toFixed(2)}%`}
            />
            <ReactTooltip
                anchorSelect={`#oiv_${id}`}
                id={`tooltip_oiv_${id}`}
                place="top"
                className="z-50"
                style={{backgroundColor: "#3b485f", color: "#98a2b3"}}
            >
                <div className="standard-stack p-2">
                    <SplitLabelValue label="Long Open Interest: " className="text-white">
                        <div className="flex flex-col items-end">
                            <div className="text-white">
                                <MarketValueView
                                  market={market}
                                  value={parseUnits(longOpenInterest.toString(), market.pair.quoteToken.decimals)}
                                  className="text-white"/>
                            </div>
                            <div className="text-white">
                              ({longPercentage.toFixed(2)}%)
                            </div>
                        </div>
                    </SplitLabelValue>
                    {
                        displayTotalPrincipal &&
                        <>
                            <SplitLabelValue label="Long Principal: " className="text-white">
                                <div className="flex flex-col items-end">
                                    <div className="text-white">
                                        <MarketValueView
                                          market={market}
                                          value={parseUnits((longPrincipal || 0).toString(), market.pair.quoteToken.decimals)}
                                          className="text-white"/>
                                    </div>
                                </div>
                            </SplitLabelValue>
                            {
                                maxOpenInterest &&
                                <SplitLabelValue label="Long Principal Limit: " className="text-white">
                                  <div className="flex flex-col items-end">
                                      <div className="text-white">
                                          <MarketValueView
                                            market={market}
                                            value={parseUnits((maxOpenInterest).toString(), market.pair.quoteToken.decimals)}
                                            className="text-white"/>
                                      </div>
                                  </div>
                                </SplitLabelValue>
                            }
                        </>
                    }
                    <SplitLabelValue label="Short Open Interest: " className="text-white">
                        <div className="flex flex-col items-end">
                            <div className="text-white">
                                <MarketValueView
                                  market={market}
                                  value={parseUnits((shortOpenInterest).toString(), market.pair.quoteToken.decimals)}
                                  className="text-white"/>
                            </div>
                            <div className="text-white">
                                ({shortPercentage.toFixed(2)}%)
                            </div>
                        </div>
                    </SplitLabelValue>
                    {
                      displayTotalPrincipal &&
                      <>
                          <SplitLabelValue label="Short Principal: " className="text-white">
                              <div className="flex flex-col items-end">
                                  <div className="text-white">
                                      <MarketValueView
                                        market={market}
                                        value={parseUnits((shortPrincipal || 0).toString(), market.pair.quoteToken.decimals)}
                                        className="text-white"/>
                                  </div>
                              </div>
                          </SplitLabelValue>
                          {
                              maxShortPrincipal &&
                                <SplitLabelValue label="Short Principal: " className="text-white">
                                    <div className="flex flex-col items-end">
                                        <div className="text-white">
                                            <MarketValueView
                                              market={market}
                                              value={parseUnits((maxShortPrincipal).toString(), market.pair.quoteToken.decimals)}
                                              className="text-white"/>
                                        </div>
                                    </div>
                                </SplitLabelValue>
                          }
                      </>
                    }
                </div>
            </ReactTooltip>
        </div>
    );
};
