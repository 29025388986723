import {PositionStatus} from "@/components/Perps/types";
import {Tooltip as ReactTooltip} from "react-tooltip";
import {SizeTooltip} from "@/components/Perps/positions/SizeTooltip";
import React from "react";
import {MarketValueView} from "@/components/Perps/market/MarketValueView";

export interface Props {
  positionStatus: PositionStatus
}

export const SizeView = ({positionStatus}: Props) => {
  const {position, market} = positionStatus;

  const getSize = () => {
    if (position.side === "SHORT") {
      return BigInt(position.collateralAmountRaw);
    } else {
      return BigInt(position.principalRaw) + BigInt(position.downPaymentRaw);
    }
  }

  return (
    <>
      <MarketValueView
        market={market}
        className="cursor-pointer"
        id={`size_${position.id}`}
        value={getSize()}
      />
      <ReactTooltip
        anchorSelect={`#size_${position.id}`}
        id={`tooltip_size_${position.id}`}
        place="top"
        className="z-50"
        style={{backgroundColor: "#3b485f", color: "#98a2b3"}}
      >
        <SizeTooltip positionStatus={positionStatus}/>
      </ReactTooltip>
    </>
  )
}
