import {PositionStatus} from "@/components/Perps/types";
import {SplitLabelValue} from "@/components/SplitLabelValue";
import {MarketValueView} from "@/components/Perps/market/MarketValueView";
import React from "react";
import {formatValue} from "@/util/converters";

export const PnLTooltip = ({positionStatus}: { positionStatus: PositionStatus }) => {
  const position = positionStatus.position;
  const token = position.side === "LONG" ? positionStatus.market.pair.quoteToken : positionStatus.market.pair.baseToken;
  const downPayment = BigInt(position.downPaymentRaw);
  const netValue = BigInt(positionStatus.netValue);
  const fee = BigInt(positionStatus.fee);

  const hourlyBorrowRate = positionStatus.apr / (365 * 24);
  const hourlyInterestAmountFormatted = position.principal * hourlyBorrowRate;

  return (
    <div className="standard-stack p-2">
      <SplitLabelValue label="Down Payment" className="text-white">
        <MarketValueView value={downPayment} market={positionStatus.market} />
      </SplitLabelValue>
      <hr className="border-neutral-content/20"/>
      <SplitLabelValue label={"Interest Paid"} className="text-white">
        <MarketValueView value={positionStatus.interestPaidEthValue} market={positionStatus.market} className="justify-end" />
        {
          position.side === "SHORT" &&
            <span>{formatValue(positionStatus.interestPaid, 6, token.decimals)} {token.symbol}</span>
        }
      </SplitLabelValue>
      <SplitLabelValue label="Borrow Rate" className="text-white">
        <div className="standard-stack items-end">
          <div>{(hourlyBorrowRate * 100).toFixed(4)}% / 1h</div>
        </div>
      </SplitLabelValue>
      <SplitLabelValue label="Borrow Amount" className="text-white">
        <div className="standard-stack items-end">
          <div>{hourlyInterestAmountFormatted.toLocaleString([], {maximumFractionDigits: 10})} {positionStatus.position.side === 'LONG' ? "ETH" : token.symbol} / 1h</div>
        </div>
      </SplitLabelValue>
      <hr className="border-neutral-content/20"/>
      <SplitLabelValue label="PnL" className="text-white">
        <MarketValueView
          className="flex-col items-end"
          value={netValue + fee - downPayment}
          valueForPercent={netValue + fee}
          original={downPayment} market={positionStatus.market}/>
        </SplitLabelValue>
      <hr className="border-neutral-content/20"/>
      <SplitLabelValue label="Close Fees" className="text-white">
        <MarketValueView value={fee} market={positionStatus.market} />
      </SplitLabelValue>
      <SplitLabelValue label="You Receive" className="text-white">
        <MarketValueView value={netValue} market={positionStatus.market} fractions={4} />
      </SplitLabelValue>
    </div>
  )
}
