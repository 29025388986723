import {PerpPositionStatus, PositionStatus} from "@/components/Perps/types";
import {SplitLabelValue} from "@/components/SplitLabelValue";
import React from "react";
import {formatValue, multiplyByPrice} from "@/util/converters";
import {useEthPrice} from "@/contexts/EthPriceContext";
import {isUsdEthMarket} from "@/util/chainConstants";

export const SizeTooltip = ({positionStatus}: { positionStatus: PositionStatus }) => {
    const {ethPrice} = useEthPrice();
    const {position, market} = positionStatus;

    const isLong = positionStatus.position.side === "LONG";
    const size = isLong ? position.principal + position.downPayment : position.collateralAmount;
    let baseToken = market.pair.baseToken;
    let quoteToken = market.pair.quoteToken;
    const isUsdEth = isUsdEthMarket(market);

    return (
        <div className="standard-stack p-2">
            <SplitLabelValue label="Collateral" className="text-white">
                <div className="flex flex-row gap-2">
                    <span>{positionStatus.position.collateralAmount.toLocaleString([], {maximumFractionDigits: 4})}</span>
                    <span>{isLong ? baseToken.symbol : quoteToken.symbol}</span>
                </div>
            </SplitLabelValue>
            <SplitLabelValue label="Principal" className="text-white">
                <div className="flex flex-row gap-2">
                    <span>{positionStatus.position.principal.toLocaleString([], {maximumFractionDigits: 4})}</span>
                    <span>{isLong ? quoteToken.symbol : baseToken.symbol}</span>
                </div>
            </SplitLabelValue>
        </div>
    )
}
