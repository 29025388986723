import {getLeverage} from "@/components/Perps/util";
import {Market, PerpToken, Position, Token} from "@/components/Perps/types";
import classNames from "classnames";
import {twMerge} from "tailwind-merge";
import {isUsdEthMarket, isUsdToken} from "@/util/chainConstants";

interface Props {
  position: Position;
  market: Market;
  onMarketClicked: (p: Market) => any;
  imageSize?: number;
  className?: string;
}

export const PositionHeader = ({position, market, onMarketClicked, imageSize, className}: Props) => {
  let side = position.side;
  let baseToken = market.pair.baseToken;
  let quoteToken = market.pair.quoteToken;

  if (isUsdEthMarket(market)) {
    side = side === "LONG" ? "SHORT" : "LONG";
    baseToken = market.pair.quoteToken;
    quoteToken = market.pair.baseToken;
  }

  const formatSymbol = (symbol: string) => {
    if (symbol === "WETH") {
      return "ETH";
    }
    return symbol;
  }
  return (
    <div className={twMerge("flex flex-row items-center gap-2 hover:cursor-pointer hover:underline hover:underline-offset-2 text-sm", className || '')}
         onClick={() => onMarketClicked(market)}>
      <img src={baseToken.imageUrl} alt={baseToken.symbol} className={`w-${imageSize || 8} h-${imageSize || 8} rounded-full`}/>
      <div>
        <div className={classNames({
          "text-call": side === "LONG",
          "text-put": side === "SHORT",
        })}>
          {getLeverage(position)}x {side}
        </div>
        <span>{formatSymbol(baseToken.symbol)}<span className="text-neutral-content">/{formatSymbol(quoteToken.symbol)}</span></span>
      </div>
    </div>
  )
}
